<template>
  <div class="  shadow-none m-0">
    <div class="  ">
      <div class="row justify-content-end mb-3 align-items-end">
        <div class="col-auto ">
          <button class=" btn btn-success rounded-pill " @click=" fnAddNewAddress() "
            v-text=" $t('general.button.add') ">
          </button>
        </div>
      </div>

      <div v-show="addressesList.length > 0 ">
        <!-- Mobile -->
        <div class=" d-lg-none ">
          <table class="table ecart-table animated fadeIn ">
            <thead>
              <tr>
                <th>
                  <p>
                    <span v-text="$t('general.form.addresses') "></span>
                    <i class=" fa fa-question-circle fa-sm ml-1"
                      v-b-popover.hover.top=" $t('dashboard.store.addresses.message') ">
                    </i>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (address, index ) in addressesList " :key=" index ">
                <td>
                  <p>
                    <small class="f-w-500">
                      <span class="text-muted" v-text="'ID: '"></span>
                      <span v-text="address.id"></span>
                    </small>
                  </p>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <button class=" btn btn-sm btn-success rounded-pill w-100"
                        @click=" fnUpdateAddress( address.id ) ">
                        <strong class="  " v-text=" address.address_name"></strong>
                      </button>
                    </div>
                    <div class="col-12">
                      <p class="">
                        <span v-text=" fnFormatAddress(address)  "></span>
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center ">
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <p class="m-0">
                            <span v-text=" $t('general.form.phone') + ': ' "></span>
                            <span v-text=" address.phone "></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button class="btn btn-sm rounded-pill" :class="address.default ? 'btn-success' : 'btn-light' "
                        @click="fnApiUpdateDefault( index ) ">
                        <span>Default</span>
                      </button>
                    </div>
                    <div class="col-auto ">
                      <button class=" btn btn-danger btn-sm px-2 rounded-pill " @click=" fnApiDeleteAddress(index) ">
                        <i class="fa fa-trash "></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Desktop -->
        <div class=" d-none d-lg-block table-striped table-responsive ">
          <table class=" table ">
            <thead class=" ">
              <tr>
                <th class=" text-center " v-text="$t('tables.default')"></th>
                <th class=" text-truncate " v-text=" $t('dashboard.store.addresses.addressName') "> </th>
                <th class=" text-truncate " v-text=" $t('dashboard.store.addresses.fullName') "> </th>
                <th class=" text-center " v-text=" $t('dashboard.store.addresses.country') "> </th>
                <th class=" text-center " v-text=" $t('dashboard.store.addresses.state') ">
                </th>
                <th class=" text-center " v-text="$t('general.form.zipCode')"> </th>
                <!-- <th class=" text-center " v-text=" $t('dashboard.store.addresses.phone') ">
              </th> -->
                <th class=" text-center "> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" ( address, index ) in addressesList " :key=" index ">
                <td class=" ">
                  <div class="media-body switch mx-auto">
                    <label class="switch">
                      <input type="checkbox" v-model="address.default" :id=" 'address' + address.id  "><span
                        class="switch-state " :class=" address.default ? 'bg-success': '' "
                        @click=" fnApiUpdateDefault( index ) "></span>
                    </label>
                  </div>
                </td>
                <td>
                  <button class=" btn p-0 w-100 text-left">
                    <span class=" text-success " @click=" fnUpdateAddress( address.id )">
                      <strong class=" d-none d-sm-inline " v-text=" fnCutText( address.address_name , 50) "></strong>
                      <strong class=" d-sm-none " v-text=" fnCutText( address.address_name , 20) "></strong>
                    </span>
                  </button>
                </td>
                <td>
                  <p class=" text-truncate" v-if="address.first_name">
                    <span v-text=" address.first_name + ' ' + address.last_name "></span>
                  </p>
                  <div v-else>
                    <button class=" btn text-info p-0 w-100 text-left" @click=" fnUpdateAddress( address.id )">
                      <i class="fa fa-info-circle mr-1"></i>
                      <strong v-text=" $t('general.button.incomplete') ">
                      </strong>
                    </button>
                  </div>
                </td>
                <td class=" text-center ">
                  <i class="flag-icon mr-2" :class=" 'flag-icon-' + (address.country.code).toLowerCase()   "></i><strong
                    v-text=" address.country.code "></strong>
                </td>
                <td class=" text-center ">
                  <p class=" text-truncate">
                    <span v-text=" address.state.code || address.state.name  "></span>
                  </p>
                </td>
                <td class=" text-center ">
                  <p>
                    <span v-text=" address.postal_code "></span>
                  </p>
                </td>
                <!-- <td class=" text-center ">
                <p>
                  <span v-text=" address.phone "></span>
                </p>
              </td> -->
                <td class="text-center ">
                  <button class=" btn btn-danger btn-xs rounded-pill " @click=" fnApiDeleteAddress(index)"
                    :disabled="deleteAddresId == address.id ">
                    <i class="fa" :class=" (deleteAddresId == address.id)  ? 'fa-spinner fa-spin' : 'fa-trash' "></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <no-data :dataMessage="$t('noDataMessages.storeAddressesList') " v-show=" addressesList.length == 0 "
        :isLoading="loading.address" @btn-fn=" fnAddNewAddress() ">
      </no-data>


    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex'
  export default {
    computed: {
      ...mapState('EcartStore', ['modal', 'addressesList', 'loading', 'deleteAddresId', 'integration']),
    },
    methods: {
      ...mapMutations('EcartStore', ['changeModal', 'resetAddress', 'setUpdateAddressId']),
      ...mapActions('EcartStore', ['fnApiUpdateDefault', 'fnApiDeleteAddress']),
      fnAddNewAddress() {
        this.setUpdateAddressId(null);
        this.changeModal({
          address: true
        });
      },
      fnUpdateAddress(addressId) {
        this.setUpdateAddressId(addressId);
        this.changeModal({
          address: true
        });
      }
    }
  }
</script>

<style>

</style>